import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { i18nReducer } from 'redux-react-i18n';
import commonReducer from './common-reducer';
import loginReducer from '../../models/login/login.reducer';
/** Add Multiple Reducer */
var reducers = {
    i18nReducer: i18nReducer,
    form: form,
    commonReducer: commonReducer,
    loginReducer: loginReducer
};
export default combineReducers(reducers);
