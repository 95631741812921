var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
/* **********************************  Keys Defination *********************************
ModuleName                  : Display Module Name
ModuleKeys                  : Module Key for Identification for Module
iconClass                   : Display Module Icon
hasNavChild                 : Check Sub Page View Display
routeUrl                    : Navigation Url/Route
isShow                      : Toggle View handle Collapse/Expand
permissionType              : Identification of Permission
componentElementOnPage      : Element's Display on Parent Pages
isVisibleOnNavigation       : Display In Navigation Component Parent/Child
title                       : Display SubChild Tile Name
breadcrumbsTitle            : Display in BreadCrumb Name
breadcrumbsParentPath       : Display BreadCrumb Path
breadcrumbsParentTitle      : Display BreadCrumb Parent Title
oslUrl                      : For Validate OSL path if allow permission
oslMethod                   : For Validate OSL Method if allow permission
searchParams                : For Global Search
uniqueKey                   : Unique Identification : Note :- Pattern should be Ex. [Name of Module]-[type of view]
***************************************************************************************/
export var MODULES;
(function (MODULES) {
    MODULES[MODULES["Ballot"] = 0] = "Ballot";
})(MODULES || (MODULES = {}));
export var LEFT_NAVIGATION_PAGE_URL = {
    HOME: "/",
    DASHBOARD: "/dashboard",
    BALLOTS: "/ballot",
    BALLOTDETAILS: "/ballotDetail",
    WORKITEM: "/workitem"
};
export var INVALID_URL = {
    PAGE_NOT_FOUND: "/not-found",
};
export var PAGE_CONFIG = {
    // Privilege Type
    USER_PRIVILEGE: {
        ALL: "All",
        VIEW: "View",
        ADD: "Add",
        UPDATE: "Update",
        DELETE: "Delete",
        EXPORT: "Export",
        SEQUENCE: "Sequence",
        PUBLISH: "Publish",
    },
    // HTTP METHODS
    HTTP_METHOD: {
        GET: "GET",
        POST: "POST",
        PUT: "PUT",
        DELETE: "DELETE",
    },
    PAGE_TYPE: {
        DETAIL: function (page) { return "".concat(page, "-Detail"); },
        HOME: "Home",
        MEMBER: "Member",
        ORGANIZATION: "Organization",
        COMMITTEE: "Committee",
        TASK: "Task",
        USER: "User",
        ROLE: "Role",
        FEEGROUP: "Feegroup",
        WORK_ITEM_ADMIN_TOOL: "WORK_ITEM_ADMIN_TOOL",
        BALLOT: "BALLOT",
        //Implementation will be updated in future, we will change titles in future
        SUB_HEADER_2: "SUB_HEADER_2",
        SUB_HEADER_3: "SUB_HEADER_3",
        SUB_HEADER_4: "SUB_HEADER_4",
        BALLOT_ADMIN: "BALLOT_ADMIN",
        MEMBERSHIP_RENEWAL: "MembershipRenewal",
        ADD_MEMBER: "Addmember",
        MEMBER_EXCEPTION: "MEMBER_EXCEPTION",
        BOS_VOLUME_LOCATION: "BOS_VOLUME_LOCATION",
        MANAGE_ALERTS: "MANAGE_ALERTS",
        MEMBERSHIP_REPORT: "MEMBERSHIP_REPORT",
        RENEWAL_REPORT: "RENEWAL_REPORT",
        MEETING_REPORT: "MEETING_REPORT",
        DASHBOARD: "DASHBOARD",
        DOCUMENT_UPLOAD: "DOC_UPLOAD",
        ALL_DOCUMENTS: "ALL_DOCUMENTS",
        AGENDAS: "AGENDAS",
        MINUTES: "MINUTES",
        COMMITTEE_DOC: "COMMITTEE_DOC",
        VOTE_ATTACHMENTS: "VOTE_ATTACHMENTS",
        BALLOT_QUEUE: "BALLOT_QUEUE",
        CLOSING_REPORTS: "CLOSING_REPORTS",
        BALLOT_ITEM: "BALLOT_ITEM",
        LITIGATION_HOLD: "LITIGATION_HOLD",
        COLLABORATION_AREA_DRAFT: "COLLABORATION_AREA_DRAFT",
        BALLOTS: "BALLOTS",
        BALLOTDETAILS: "BALLOTDETAILS",
    },
    // Create Unique Key For page Routes
    uniqueKey: function (page, privilege) {
        if (page === void 0) { page = ""; }
        if (privilege === void 0) { privilege = ""; }
        // Page Will Generate Combination of Page Name and Permission its self.
        // Unique Identification : Note :- Pattern should be Ex. [Name of Module]-[type of view]
        return "".concat(page, "-").concat(privilege);
    },
};
// Set All Page Configuration
export var HTTP_METHOD = PAGE_CONFIG.HTTP_METHOD, PAGE_TYPE = PAGE_CONFIG.PAGE_TYPE, USER_PRIVILEGE = PAGE_CONFIG.USER_PRIVILEGE, uniqueKey = PAGE_CONFIG.uniqueKey;
export var NAVIGATION = [
    (_a = {
            ModuleName: "Home",
            ModuleKeys: MODULES[0],
            iconClass: "",
            isShow: false, // For Toggle Navgation if Required
            hasNavChild: true, // For Check Has Page Exist
            routeUrl: "#",
            isVisibleOnNavigation: false
        },
        _a[MODULES[0]] = [
            {
                // DashBoard View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "/",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "Dashboard",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "",
                oslMethod: "",
                uniqueKey: uniqueKey(PAGE_TYPE.HOME, USER_PRIVILEGE.VIEW),
                additionalPermission: [],
                searchParams: null,
            },
        ],
        _a),
    (_b = {
            ModuleName: "Member",
            ModuleKeys: MODULES[1],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _b[MODULES[1]] = [
            {
                // Member List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Members",
                breadcrumbsTitle: "Members",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
            {
                // Organization List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Organizations",
                breadcrumbsTitle: "Organizations",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
            {
                // Fee Group List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Fee Group",
                breadcrumbsTitle: "Fee Group",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/feeGroup/*",
                uniqueKey: uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
        ],
        _b),
    (_c = {
            ModuleName: "Committee",
            ModuleKeys: MODULES[2],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "#",
            breadcrumbsRootTitle: "",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _c[MODULES[2]] = [
            {
                // Committee List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: false,
                title: "Committee Management",
                breadcrumbsTitle: "Committees",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/committeeManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
            },
        ],
        _c),
    (_d = {
            ModuleName: "Renewal Tasks",
            ModuleKeys: MODULES[3],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "#",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _d[MODULES[3]] = [
            {
                // Renewal List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: false,
                title: "Renewal Tasks",
                breadcrumbsTitle: "Renewal Tasks",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/task/*",
                uniqueKey: uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
            },
        ],
        _d),
    (_e = {
            ModuleName: "Work Item",
            ModuleKeys: MODULES[4],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "workitem",
            breadcrumbsRootTitle: "",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _e[MODULES[4]] = [
            {
                // Work Item Admin Tools page
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: false,
                title: "Work Item Admin Tool",
                breadcrumbsTitle: "Work Item Admin Tool",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/workItemAdmin/*",
                uniqueKey: uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
            },
        ],
        _e),
    (_f = {
            ModuleName: "Admin",
            ModuleKeys: MODULES[6],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _f[MODULES[6]] = [
            {
                // User Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "User Permissions",
                breadcrumbsTitle: "Users",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/usermanagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Role List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Roles",
                breadcrumbsTitle: "Roles",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/roledetail/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Membership Renewal [View] Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Membership Renewal",
                breadcrumbsTitle: "Membership Renewal",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberShipRenewal/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Manual Order View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Manual Order",
                breadcrumbsTitle: "Manual Order",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/addMember/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View MEMBER EXCEPTION List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Membership Exceptions",
                breadcrumbsTitle: "Membership Exceptions",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/membership-exception/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View BOS VOLUME LOCATION List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "BOS Volume Location",
                breadcrumbsTitle: "BOS Volume Location",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/bosVolume/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View Manage Alerts  List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Manage Alerts ",
                breadcrumbsTitle: "Manage Alerts ",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/managealerts/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _f),
    (_g = {
            ModuleName: "Reports",
            ModuleKeys: MODULES[7],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _g[MODULES[7]] = [
            {
                // Memberships Reports Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Membership Reports",
                breadcrumbsTitle: "Membership Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/membership-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Renewal Report View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Renewal Reports",
                breadcrumbsTitle: "Renewal Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/renewal-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Meeting Reports Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Meeting Reports",
                breadcrumbsTitle: "Meeting Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/meeting-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _g),
    (_h = {
            ModuleName: "Documents",
            ModuleKeys: MODULES[8],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "#",
            isExternalUrl: false,
            isVisibleOnNavigation: true
        },
        _h[MODULES[8]] = [
            {
                // Document Dashboard Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Dashboard",
                breadcrumbsTitle: "Dashboard",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "",
                uniqueKey: uniqueKey(PAGE_TYPE.DASHBOARD, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isStatic: true,
            },
        ],
        _h),
];
export var NAVIGATIONHEADER = [
    (_j = {
            ModuleName: "Home",
            ModuleKeys: MODULES[0],
            iconClass: "",
            isShow: false, // For Toggle Navgation if Required
            hasNavChild: true, // For Check Has Page Exist
            routeUrl: "#",
            isVisibleOnNavigation: false
        },
        _j[MODULES[0]] = [
            {
                // DashBoard View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "/",
                isVisibleOnNavigation: false,
                title: "",
                breadcrumbsTitle: "Dashboard",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "",
                oslMethod: "",
                uniqueKey: uniqueKey(PAGE_TYPE.HOME, USER_PRIVILEGE.VIEW),
                additionalPermission: [],
                searchParams: null,
            },
        ],
        _j),
    (_k = {
            ModuleName: "Member",
            ModuleKeys: MODULES[1],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _k[MODULES[1]] = [
            {
                // Member List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Members",
                breadcrumbsTitle: "Members",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
            {
                // Organization List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Organizations",
                breadcrumbsTitle: "Organizations",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
            {
                // Fee Group List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Fee Group",
                breadcrumbsTitle: "Fee Group",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/feeGroup/*",
                uniqueKey: uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                isExternalUrl: true,
            },
        ],
        _k),
    (_l = {
            ModuleName: "Committee",
            ModuleKeys: MODULES[2],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "#",
            breadcrumbsRootTitle: "",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _l[MODULES[2]] = [
            {
                // Committee List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: false,
                title: "Committee Management",
                breadcrumbsTitle: "Committees",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/committeeManagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
            },
        ],
        _l),
    (_m = {
            ModuleName: "Renewal Tasks",
            ModuleKeys: MODULES[3],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "#",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _m[MODULES[3]] = [
            {
                // Renewal List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: false,
                title: "Renewal Tasks",
                breadcrumbsTitle: "Renewal Tasks",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/task/*",
                uniqueKey: uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
            },
        ],
        _m),
    (_o = {
            ModuleName: "Work Item",
            ModuleKeys: MODULES[4],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "#",
            breadcrumbsRootTitle: "",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _o[MODULES[4]] = [
            {
                // Work Item Admin Tools page
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: false,
                title: "Work Item Admin Tool",
                breadcrumbsTitle: "Work Item Admin Tool",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/workItemAdmin/*",
                uniqueKey: uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
            },
        ],
        _o),
    (_p = {
            ModuleName: "Ballot",
            ModuleKeys: MODULES[5],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            breadcrumbsRootTitle: "Ballots",
            oslUrl: "",
            isVisibleOnNavigation: true,
            isExternalUrl: true
        },
        _p[MODULES[5]] = [
            {
                // Ballots Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [uniqueKey(PAGE_TYPE.BALLOTS, USER_PRIVILEGE.VIEW)],
                routeUrl: "ballot",
                isVisibleOnNavigation: true,
                title: "Ballots",
                breadcrumbsTitle: "Ballots",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/ballots/*",
                oslMethod: HTTP_METHOD.GET,
                uniqueKey: uniqueKey(PAGE_TYPE.BALLOTS, USER_PRIVILEGE.VIEW),
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                //Implementation will be updated in future, we will change the osl-url in future and titles
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "workitem",
                isVisibleOnNavigation: true,
                title: "Work Items",
                breadcrumbsTitle: "Work Items",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/ballots/workItem/*",
                uniqueKey: uniqueKey(PAGE_TYPE.SUB_HEADER_2, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                //Implementation will be updated in future, we will change the osl-url in future and titles
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Sub Menu 3",
                breadcrumbsTitle: "Sub Menu 3",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/ballots/sub-menu3/*",
                uniqueKey: uniqueKey(PAGE_TYPE.SUB_HEADER_3, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                //Implementation will be updated in future, we will change the osl-url in future and titles
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Sub Menu 4",
                breadcrumbsTitle: "Sub Menu 4",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/ballots/sub-menu4/*",
                uniqueKey: uniqueKey(PAGE_TYPE.SUB_HEADER_4, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _p),
    (_q = {
            ModuleName: "Admin",
            ModuleKeys: MODULES[6],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _q[MODULES[6]] = [
            {
                // User Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "User Permissions",
                breadcrumbsTitle: "Users",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/usermanagement/*",
                uniqueKey: uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Role List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Roles",
                breadcrumbsTitle: "Roles",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/roledetail/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Membership Renewal [View] Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Membership Renewal",
                breadcrumbsTitle: "Membership Renewal",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/memberShipRenewal/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Manual Order View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Manual Order",
                breadcrumbsTitle: "Manual Order",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/addMember/*",
                uniqueKey: uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View MEMBER EXCEPTION List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Membership Exceptions",
                breadcrumbsTitle: "Membership Exceptions",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/membership-exception/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View BOS VOLUME LOCATION List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "BOS Volume Location",
                breadcrumbsTitle: "BOS Volume Location",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/bosVolume/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // View Manage Alerts  List Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Manage Alerts ",
                breadcrumbsTitle: "Manage Alerts ",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "/managealerts/*",
                uniqueKey: uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _q),
    (_r = {
            ModuleName: "Reports",
            ModuleKeys: MODULES[7],
            iconClass: "",
            isShow: false,
            hasNavChild: true,
            routeUrl: "#",
            isVisibleOnNavigation: true
        },
        _r[MODULES[7]] = [
            {
                // Memberships Reports Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Membership Reports",
                breadcrumbsTitle: "Membership Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/membership-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Renewal Report View Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Renewal Reports",
                breadcrumbsTitle: "Renewal Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/renewal-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
            {
                // Meeting Reports Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: "#",
                isVisibleOnNavigation: true,
                title: "Meeting Reports",
                breadcrumbsTitle: "Meeting Reports",
                breadcrumbsParentPath: "/",
                breadcrumbsParentTitle: "",
                oslUrl: "/reports/meeting-reports/*",
                uniqueKey: uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isExternalUrl: true,
            },
        ],
        _r),
    (_s = {
            ModuleName: "Documents",
            ModuleKeys: MODULES[8],
            iconClass: "",
            isShow: false,
            hasNavChild: false,
            routeUrl: "#",
            isExternalUrl: true,
            isVisibleOnNavigation: true
        },
        _s[MODULES[8]] = [
            {
                // Document Dashboard Permission
                permissionType: USER_PRIVILEGE.VIEW,
                componentElementOnPage: [],
                routeUrl: LEFT_NAVIGATION_PAGE_URL.DASHBOARD,
                isVisibleOnNavigation: true,
                title: "Dashboard",
                breadcrumbsTitle: "Dashboard",
                breadcrumbsParentPath: "",
                breadcrumbsParentTitle: "",
                oslUrl: "",
                uniqueKey: uniqueKey(PAGE_TYPE.DASHBOARD, USER_PRIVILEGE.VIEW),
                oslMethod: HTTP_METHOD.GET,
                additionalPermission: [],
                searchParams: null,
                isStatic: true,
            },
        ],
        _s),
];
