var _a, _b, _c;
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./src/index.css";
import App from "./src/App";
import reportWebVitals from "./src/reportWebVitals";
import { ViewportProvider } from "./src/hooks/useViewPort";
import { Provider } from "react-redux";
import { store } from "./src/redux/store";
import setupInterceptors from "./src/interceptor";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
datadogRum.init({
    applicationId: (_a = process.env.REACT_APP_DATADOG_APPLICATION_ID) !== null && _a !== void 0 ? _a : "",
    clientToken: (_b = process.env.REACT_APP_DATADOG_CLIENT_TOKEN) !== null && _b !== void 0 ? _b : "",
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    version: "1.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
});
datadogLogs.init({
    clientToken: (_c = process.env.REACT_APP_DATADOG_CLIENT_TOKEN) !== null && _c !== void 0 ? _c : "",
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
});
var root = ReactDOM.createRoot(document.getElementById("root"));
// Configure Store
setupInterceptors(store);
root.render(_jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(ViewportProvider, { children: _jsx(App, {}) }) }) }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
