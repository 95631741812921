var en = {
    // Access related messages 1001 to 1100
    "1002": { type: "error", text: "You are not able to login due to invalid token." },
    "1003": { type: "error", text: "Token could not be verified from Redis client." },
    "1004": { type: "error", text: "Your session has expired. Please logout, then login again." },
    "1005": { type: "error", text: "Your session has expired. Please logout, then login again." },
    "1010": { type: "error", text: "User name or password missing." },
    "1011": { type: "error", text: "Error occurred during login. Please try again." },
    "1014": { type: "error", text: "Access denied." },
    //User preferences related messages
    "1015": { type: "success", text: "Preference set successfully." },
    "1016": { type: "error", text: "Failed to set User Preferences." },
    "1017": { type: "error", text: "Failed to save ballot header." },
};
export default en;
