var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { default as React } from "react";
import { Form } from "react-bootstrap";
var RenderInputText = function (_a) {
    var label = _a.label, id = _a.id, input = _a.input, type = _a.type, placeholder = _a.placeholder, required = _a.required, defaultvalue = _a.defaultvalue, disabled = _a.disabled, onPaste = _a.onPaste, testId = _a.testId, _b = _a.meta, touched = _b.touched, error = _b.error, maxlength = _a.maxlength, fieldError = _a.fieldError, className = _a.className;
    return (_jsxs(Form.Group, { children: [label && (_jsxs(Form.Label, { className: "".concat(fieldError ? "invalid-feedback block" : "", " block form-label"), htmlFor: id, children: [required && _jsx("i", { className: "fas fa-asterisk" }), label] })), _jsx(Form.Control, __assign({ className: fieldError ? "error is-invalid ".concat(className) : "".concat(className) }, input, { "data-testid": testId, id: id, maxLength: maxlength, type: type, value: defaultvalue, onPaste: onPaste, disabled: disabled, isInvalid: touched && error, placeholder: placeholder || "" })), touched && error && (_jsx("small", { id: "helpText", className: "invalid-feedback", children: error })), fieldError && (_jsx("div", { id: "helpText", className: "invalid-feedback block", children: error }))] }));
};
export default RenderInputText;
