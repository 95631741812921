var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Form } from "react-bootstrap";
import CustomTooltip from "../../components/atoms/CustomTooltip";
var ReduxRadio = function (props) {
    var input = props.input, selectedValue = props.selectedValue, label = props.label, id = props.id, options = props.options, title = props.title, required = props.required, inline = props.inline, disabled = props.disabled, requiredError = props.requiredError, dataTestId = props.dataTestId, _a = props.meta, touched = _a.touched, error = _a.error;
    var uniqueId = id ? id : input.name;
    return (_jsxs(Form.Group, { children: [label && (_jsxs(Form.Label, { className: touched && error ? "is-invalid" : "", children: [required && _jsx("i", { className: "fas fa-asterisk" }), "\u00A0", label] })), options.map(function (item) {
                return (_jsxs("div", { className: "custom-input-box", children: [_jsx(Form.Check, __assign({}, input, { "data-testid": dataTestId, disabled: disabled || item.isDisabled, inline: inline, id: item.id + "_" + uniqueId, type: "radio", className: item.value === selectedValue ? "astm-custom-radio active".concat(touched && error ? " is-invalid" : "") : "astm-custom-radio ".concat(touched && error ? " is-invalid" : ""), label: item.label, value: item.value, checked: item.value === selectedValue })), item.siblingComponent && title && _jsx(CustomTooltip, { title: title, placement: "top", className: "custom-tooltip-panel", HTMLElement: _jsx("i", { "aria-hidden": "true", className: "far fa-info-circle astm-icon ml8" }) })] }, item.id));
            }), touched && !disabled && error && _jsx("div", { className: "invalid-feedback", children: requiredError ? requiredError : error })] }));
};
export default ReduxRadio;
