import axios from "axios";
import commonActions, { SetWorkItemListData } from "../../common.actions";
import getApiUrl from "../../helpers/api-urls";
import { getApiUniqueRequestId } from "../../helpers/util-common";
export var getWorkItemListAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getWorkItemList");
    axios
        .post(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.status) {
            callback(response.data.content);
            dispatch(SetWorkItemListData(response.data.content));
            dispatch(commonActions.setLoader(false));
        }
        else {
            // Handle error case
            dispatch(SetWorkItemListData({ hasError: true }));
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
