import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Form } from "react-bootstrap";
import CustomTooltip from "../../components/atoms/CustomTooltip";
var RenderTypeahead = function (props) {
    var input = props.input, label = props.label, title = props.title, id = props.id, options = props.options, placeholder = props.placeholder, required = props.required, disabled = props.disabled, change = props.change, selected = props.selected, inputChange = props.inputChange, isText = props.isText, multiple = props.multiple, keyDown = props.keyDown, customClass = props.customClass, clearTypeahead = props.clearTypeahead, _a = props.meta, touched = _a.touched, error = _a.error, _b = props.fieldError, fieldError = _b === void 0 ? false : _b, customErrorMsg = props.customErrorMsg, inputProps = props.inputProps;
    var inputRef = useRef(null);
    var _c = useState(""), textValue = _c[0], setTextValue = _c[1];
    var _d = useState("add-ballot-header-committee"), customStyleCLass = _d[0], setCustomClass = _d[1];
    useEffect(function () {
        if (!isText) {
            inputRef && inputRef.current && inputRef.current.clear();
        }
    }, [isText]);
    useEffect(function () {
        if (inputProps === null || inputProps === void 0 ? void 0 : inputProps.readOnly) {
            setCustomClass("");
        }
        else {
            setCustomClass("add-ballot-header-committee");
        }
    }, [inputProps === null || inputProps === void 0 ? void 0 : inputProps.readOnly]);
    useEffect(function () {
        var _a;
        clearTypeahead && ((_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.clear());
    }, [clearTypeahead]);
    var handleKeyDown = function (e) {
        var _a;
        if (e.key === "Enter" && !multiple) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
        setTimeout(function () {
            setTextValue(e.target.value);
            keyDown && keyDown(e, textValue);
        }, 0);
    };
    var addRemoveTypeaheadClass = function (event) {
        var keysToAddClassOn = ["Enter", "Backspace"];
        if (event.key === "ArrowDown") {
            event.target.value && setCustomClass("");
        }
        else if (keysToAddClassOn.indexOf(event.key) > -1) {
            (inputProps === null || inputProps === void 0 ? void 0 : inputProps.readOnly) ? setCustomClass("") : setCustomClass("add-ballot-header-committee");
        }
    };
    return (_jsxs(Form.Group, { children: [label && (_jsxs(Form.Label, { className: "".concat(fieldError ? "invalid-feedback block" : "", " block form-label"), htmlFor: id, children: [required && _jsx("i", { className: "fas fa-asterisk" }), label, title && _jsx(CustomTooltip, { title: title, placement: "top", className: "custom-tooltip-panel", HTMLElement: _jsx("i", { "aria-hidden": "true", className: "far fa-info-circle astm-icon ml8" }) })] })), _jsx(Typeahead, { ref: inputRef, id: id, size: "sm", placeholder: placeholder, inputProps: inputProps, className: "".concat(fieldError ? "error is-invalid" : "", " ").concat(customStyleCLass), "aria-label": input.name, defaultSelected: selected, isInvalid: touched && error && error.length > 0, multiple: multiple, disabled: disabled, onChange: function (e) {
                    if (inputProps === null || inputProps === void 0 ? void 0 : inputProps.readOnly) {
                        setCustomClass("");
                    }
                    else {
                        setCustomClass("add-ballot-header-committee");
                    }
                    change(e);
                }, onInputChange: inputChange, options: options, selected: selected, onKeyDown: function (e) {
                    addRemoveTypeaheadClass(e);
                    if (e.key === "Enter") {
                        setCustomClass("add-ballot-header-committee");
                    }
                    handleKeyDown(e);
                }, emptyLabel: (id === "AdvBallotBallotDesignation" || id === "AdvBallotCommitteeDesignation") && textValue ? "Found no matching records for \"".concat(textValue, "\"") : customErrorMsg ? customErrorMsg : "No match found." }), touched && error && (_jsx("div", { id: "helpText", className: "invalid-feedback", children: error }))] }));
};
export default RenderTypeahead;
