import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import lodash from "lodash";
import utilCommon, { isReactAppAuthorized } from "../helpers/util-common";
import { useNavigate } from "react-router-dom";
import { validateUrlParams } from "../helpers/util-common";
import { MODULES } from "../models/navigation.models";
import { FeatureFlag } from "../provider/flagFeature";
var NavigationMenu = function (props) {
    var history = props.history;
    var featureData = useContext(FeatureFlag);
    var isUserAuthorized = isReactAppAuthorized();
    var _a = useState([]), navigationMenu = _a[0], setNavigationMenu = _a[1];
    var navigate = useNavigate();
    useEffect(function () {
        if (isUserAuthorized) {
            utilCommon.validateNavigationPermission(featureData === null || featureData === void 0 ? void 0 : featureData.FlagFeaturesData).then(function (result) {
                setNavigationMenu(result);
            });
        }
    }, [featureData]);
    // Validate Active Items : Returns Active or Not
    var activeItems = function (items, navIndex) {
        var _a, _b;
        var currentRoute = ((_a = history === null || history === void 0 ? void 0 : history.location) === null || _a === void 0 ? void 0 : _a.pathname) && ((_b = history === null || history === void 0 ? void 0 : history.location) === null || _b === void 0 ? void 0 : _b.pathname); // Get the key from the route;
        if (currentRoute && validateUrlParams(items.routeUrl, currentRoute) === currentRoute) {
            return true;
        }
        else if (currentRoute && items[MODULES[navIndex]] && items[MODULES[navIndex]].length > 0) {
            var filterResult = items[MODULES[navIndex]].filter(function (filterItems) {
                return validateUrlParams(filterItems.routeUrl, currentRoute) === currentRoute.substring(1);
            });
            return filterResult && filterResult[0];
        }
    };
    // Check Parent Url has exist hash
    var validateRouteUrl = function (url) {
        if (url && url.indexOf("#") > -1) {
            return url;
        }
        return "/".concat(url);
    };
    // Render Parent Menu
    var renderParentMenu = function (item, navIndex, featureFlag) {
        if (!featureFlag.BTAppFeature && MODULES.Ballot <= navIndex) {
            navIndex++;
        }
        return (item.isVisibleOnNavigation && (_jsxs("li", { children: [_jsx("a", { className: item.hasNavChild || item.routeUrl !== "#" ? "external-class" : "internal-class", href: lodash(item).get("isExternalUrl") ? lodash(item).get("routeUrl") : validateRouteUrl(lodash(item).get("routeUrl")), "data-testid": "navigationParent-id", children: lodash(item).get("ModuleName") }), item.hasNavChild && item[MODULES[navIndex]] && item[MODULES[navIndex]].length > 0 && (_jsxs("ul", { className: "subMenu", children: [renderChildMenu(item[MODULES[navIndex]]), " ", lodash(item).get("isExternalUrl")] }, item.uniqueKey))] }, "".concat(navIndex, "_").concat(lodash(item).get("ModuleName")))));
    };
    // Render Child Menu
    var renderChildMenu = function (subItems) {
        return (subItems &&
            subItems.length > 0 &&
            subItems.map(function (items, index) {
                return (items.isVisibleOnNavigation && (_jsx("li", { children: _jsx("a", { className: items.hasNavChild || items.routeUrl !== "#" ? "external-class" : "internal-class", onClick: function () { return navigate(lodash(items).get("isExternalUrl") ? lodash(items).get("routeUrl") : validateRouteUrl(lodash(items).get("routeUrl"))); }, "data-testid": "navigationChild-id", children: lodash(items).get("title") }) }, "".concat(index, "_").concat(lodash(items).get("title")))));
            }));
    };
    return (_jsx("div", { className: "topMenuBar", children: _jsx("div", { className: "container-fluid", children: _jsx("div", { className: "menuWrapper dms-Menu", children: _jsx("ul", { children: navigationMenu &&
                        navigationMenu.length > 0 &&
                        navigationMenu.map(function (item, navIndex) {
                            return renderParentMenu(item, navIndex, featureData.FlagFeaturesData);
                        }) }) }) }) }));
};
export default NavigationMenu;
