import { LEFT_NAVIGATION_PAGE_URL } from "./navigation.models";
import { PAGE_CONFIG } from "./navigation.models";
var USER_PRIVILEGE = PAGE_CONFIG.USER_PRIVILEGE;
export var DATE_RANGE_TYPE = {
    closeDate: "1",
};
export var API_PARAM_NULL_VALUE = " ";
export var ItemsPerPage = [
    { key: "1", text: "10", value: "10" },
    { key: "2", text: "25", value: "25" },
    { key: "3", text: "50", value: "50" },
    { key: "4", text: "75", value: "75" },
    { key: "5", text: "100", value: "100" },
];
export var CountryCodes = {
    UNITEDSTATES: { Code: "US", Name: "United States" },
    CANADA: { Code: "CA", Name: "Canada" },
};
// TODO : get domain prefix to set cookies doamin wise.
export var getDoaminPrefix = function () {
    var hostname = window.location.hostname;
    var prefixName = "";
    if (hostname) {
        var envPrefix = hostname.indexOf("-") > -1 ? hostname.split("-") : "";
        if (envPrefix && envPrefix.length > 0) {
            prefixName = "".concat(envPrefix[0].toUpperCase());
            if (prefixName == "QA") {
                prefixName = "TEST";
            }
            if (["DEV", "TEST", "STAGE"].includes(prefixName)) {
                prefixName = prefixName + "-";
            }
        }
    }
    return prefixName;
};
export var cookieKeysEnum = {
    APP_SESSIONID: "".concat(getDoaminPrefix(), "MCS-AZURE-APP-SESSION-ID"),
    accessToken: "".concat(getDoaminPrefix(), "MCS-AZURE-ACESS-TOKEN"),
};
export var getSpecialCharacterValue = function (characterString) {
    var txt = document.createElement("textarea");
    txt.innerHTML = characterString;
    return txt.value;
};
export var convertCharacterEntityToHtml = function (stringValue) {
    var listOfCharacterEntity = ["&lt;", "&gt;", "&amp;", "&quot;", "&apos;", "&cent;", "&pound;", "&yen;", "&euro;", "&copy;", "&reg;"];
    for (var _i = 0, listOfCharacterEntity_1 = listOfCharacterEntity; _i < listOfCharacterEntity_1.length; _i++) {
        var item = listOfCharacterEntity_1[_i];
        if (stringValue.includes(item)) {
            var getSpecialValue = getSpecialCharacterValue(item);
            var newStringValue = stringValue.replace(item, getSpecialValue);
            return newStringValue;
        }
    }
    return stringValue;
};
export var TypeIdForDocuments = {
    dashboard: 0,
    ballots: 1,
    ballotDetails: 2,
    workItem: 2
};
export var getDocumentTypeId = function () {
    var docCategoryId = 0;
    var urlPathName = window.location.pathname;
    switch (urlPathName) {
        case LEFT_NAVIGATION_PAGE_URL.BALLOTS:
            docCategoryId = 1;
            break;
        case LEFT_NAVIGATION_PAGE_URL.BALLOTDETAILS:
            docCategoryId = 2;
            break;
        case LEFT_NAVIGATION_PAGE_URL.WORKITEM:
            docCategoryId = 3;
            break;
    }
    return docCategoryId;
};
export var getPageId = function () {
    var pageId = 0;
    var pathname = window.location.pathname;
    var urlPathName = "/".concat(pathname.replace(/\//g, ""));
    switch (urlPathName) {
        case LEFT_NAVIGATION_PAGE_URL.BALLOTS:
            pageId = 1;
            break;
        case LEFT_NAVIGATION_PAGE_URL.BALLOTDETAILS:
            pageId = 2;
            break;
        case LEFT_NAVIGATION_PAGE_URL.WORKITEM:
            pageId = 2;
            break;
    }
    return pageId;
};
export var BALLOTSHEADERINFO = [
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "Committee",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Ballot Designation",
        hasSorting: true,
        className: "",
        sortKey: "BallotDesignation",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Items",
        hasSorting: true,
        className: "",
        sortKey: "ItemNo",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Status",
        hasSorting: true,
        className: "",
        sortKey: "BallotStatus",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Ballot Type",
        hasSorting: true,
        className: "",
        sortKey: "BallotType",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Percentage Return",
        hasSorting: true,
        className: "",
        sortKey: "PercentageReturn",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Publish Date",
        hasSorting: true,
        className: "",
        sortKey: "PublishDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Close Date",
        hasSorting: true,
        className: "",
        sortKey: "ClosingDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Modified Date",
        hasSorting: true,
        className: "",
        sortKey: "ModifiedDate",
        orderBy: 0,
        orderByIcon: 0,
        isShow: false,
    },
    {
        title: "Administrative Assistant",
        hasSorting: true,
        className: "",
        sortKey: "AdministrativeAssistant",
        orderBy: 0,
        orderByIcon: 0,
        isShow: false,
    },
    {
        title: "Staff Manager",
        hasSorting: true,
        className: "",
        sortKey: "StaffManager",
        orderBy: 0,
        orderByIcon: 0,
        isShow: false,
    },
    {
        title: "Editor(s)",
        hasSorting: true,
        className: "",
        sortKey: "Editor",
        orderBy: 0,
        orderByIcon: 0,
        isShow: false,
    },
];
export var WORKITEMSHEADERINFO = [
    {
        title: "Committee",
        hasSorting: true,
        className: "",
        sortKey: "CommitteeId",
        orderBy: 0,
        sortType: 'alphanumeric',
        orderByIcon: 0,
        isShow: true,
        isDefaultSort: true,
    },
    {
        title: "Standard",
        hasSorting: true,
        className: "",
        sortKey: "StandardDesignation",
        orderBy: 0,
        sortType: 'alphanumeric',
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Work Item",
        hasSorting: true,
        className: "",
        sortKey: "WorkItemNumber",
        orderBy: 0,
        sortType: 'alphanumeric',
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "WI Type",
        hasSorting: true,
        className: "",
        sortKey: "WorkItemTypeName",
        sortType: 'alphanumeric',
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "Title",
        hasSorting: true,
        className: "",
        sortKey: "Title",
        sortType: 'alphanumeric',
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    },
    {
        title: "WI Status",
        hasSorting: true,
        className: "",
        sortKey: "WorkItemStatus",
        sortType: 'alphanumeric',
        orderBy: 0,
        orderByIcon: 0,
        isShow: true,
    }
];
[];
export var allDocumentsDefaultState = {
    pageSize: 10,
    activePageNumber: 1,
    sortFilterInfo: { ModifiedDate: 2 },
};
export var getUrlFromCategoryId = function (documentCategoryId) {
    var documentURL = LEFT_NAVIGATION_PAGE_URL.BALLOTS;
    switch (documentCategoryId) {
        case TypeIdForDocuments.ballots:
            documentURL = "".concat(process.env.REACT_APP_REDIRECT_URI, "/ballot");
            break;
    }
    return documentURL;
};
export var SECURITY_KEY = { securitykey: process.env.REACT_APP_SECURITY_KEY };
export var BallotStatus = [
    { key: "all", text: "All", value: "All" },
    { key: "open", text: "Open", value: "Open" },
    { key: "prepublish", text: "Prepublish", value: "Prepublish" },
    { key: "closed", text: "Closed", value: "Closed" },
];
export var BallotYearsOptions = [
    {
        id: new Date().getFullYear().toString(),
        value: new Date().getFullYear().toString(),
        label: new Date().getFullYear().toString(),
        siblingComponent: false,
        isDisabled: false,
    },
    {
        id: (new Date().getFullYear() + 1).toString(),
        value: (new Date().getFullYear() + 1).toString(),
        label: (new Date().getFullYear() + 1).toString(),
        siblingComponent: true,
        isDisabled: new Date().getMonth() < 9 ? true : false,
    },
];
export var BallotStatusType = {
    Closed: "Closed",
    Open: "Open",
    Prepublish: "Prepublish",
    Publish: "Published",
};
export var BallotLevels = [
    { key: "1", text: "Main Committee", value: "1" },
    { key: "2", text: "Subcommittee", value: "2" },
    { key: "3", text: "Section", value: "3" }
];
