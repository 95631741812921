import axios from "axios";
import { cacheManager, callAPI, clearAzureAdAuthCodeAction, cookiesManager, getApiUniqueRequestId, noCookiesRedirectToLogin } from "./helpers/util-common";
import * as types from "./action-type";
import getApiUrl from "./helpers/api-urls";
import { decrypt, encrypt } from "./helpers/util-common";
import { cookieKeysEnum } from "./models/common.models";
export var setLoader = function (flag) { return ({
    type: types.SET_LOADER,
    flag: flag,
}); };
export var setminiLoader = function (flag) { return ({
    type: types.SET_MINI_LOADER,
    flag: flag,
}); };
export var setMessage = function (flag, code, uniqueRequestId, message) {
    if (uniqueRequestId === void 0) { uniqueRequestId = null; }
    if (message === void 0) { message = ""; }
    return ({
        type: types.SET_MESSAGE,
        flag: flag,
        code: code,
        message: message,
        uniqueRequestId: uniqueRequestId,
    });
};
export var showSuccessToastMesage = function (flag, code, message) {
    if (message === void 0) { message = ""; }
    return ({
        type: types.SHOW_SUCCESS_MESSAGE,
        flag: flag,
        code: code,
        message: message,
    });
};
export var showDissmissibleToastMesage = function (flag, code, dismissibleMessage, dispatch) {
    if (dismissibleMessage === void 0) { dismissibleMessage = ""; }
    return dispatch({ type: types.SHOW_DISMISSIBLE_MESSAGE, flag: flag, code: code, dismissibleMessage: dismissibleMessage });
};
export var StorCurrentLocation = function (dispatch, data) {
    dispatch({ type: types.STORE_CURRENT_LOCATION, data: data });
};
export var SetExportDataFlag = function (dispatch, data) {
    dispatch({ type: types.EXPORT_BALLOTS_LIST, data: data });
};
export var resetAdvBallotSearchFormData = function (dispatch) {
    dispatch({
        type: types.SET_BALLOT_ADVANCE_FORM_DATA,
        data: {},
    });
};
export var SetBallotDetailTagData = function (dispatch, data) {
    dispatch({ type: types.SET_BALLOT_DETAIL_TAG, data: data });
};
export var SetBallotDetailsFromData = function (dispatch, data) {
    dispatch({ type: types.SET_BALLOT_DETAILS, data: data });
};
export var SetWorkItemListData = function (data) { return ({
    type: types.SET_WORK_ITEM_LIST,
    data: data
}); };
// Action for calling logout.
export var logout = function (dispatch) {
    var url = getApiUrl("login", "logout");
    dispatch(setLoader(true));
    axios
        .post(url)
        .then(function (response) {
        if (response.data.status) {
            // Clear all cache from react app.
            cacheManager.clearSession();
            // Validate if Logout from Azure AD.
            if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
                cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
                setCurrentLocationStorage();
                setTimeout(function () {
                    window.location.assign(clearAzureAdAuthCodeAction());
                }, 500);
            }
            else {
                window.location.assign("/login");
            }
        }
        else {
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(setMessage(true, response.data.message, uniqueRequestId));
        }
        dispatch(setLoader(false));
    })
        .catch(function (err) {
        dispatch(setLoader(false));
        // Custom default code for autologout
        noCookiesRedirectToLogin();
    });
};
export var currentLocationStorage = function () {
    if (cacheManager.getItem("current-location")) {
        return { isLocation: true, location: cacheManager.getItem("current-location") };
    }
    return { isLocation: false, location: null };
};
export var setCurrentLocationStorage = function () {
    if (window.location.href) {
        var location_1 = window.location.href.replace(/.*\/\/[^\/]*/, "");
        var allowedRoutes = ["/renewal-tasks", "/admin", "/user", "/roles", "/member-management", "/committee-management", "/work-item-admin", "/ballot-admin"];
        var isAllowed = allowedRoutes.filter(function (x) { return location_1.includes(x); });
        if (isAllowed && isAllowed.length) {
            cacheManager.setItem("current-location", window.location.href.replace(/.*\/\/[^\/]*/, ""));
        }
    }
};
export var getPermissionOnPageAction = function (Input, groupInput) { return ({
    type: types.ALLOW_PERMISSION_FOR_PAGE,
    Input: Input,
    groupInput: groupInput,
}); };
// TODO : initiate Azure Request
export var initiateAzureRequestAction = function (code, sessionState, authState, cb, dispatch) {
    // TODO : Start code for init azure request.
    dispatch(setLoader(true));
    // TODO : Call Request for Init Azure in OSL.
    var url = getApiUrl("login", "initAzure");
    var authCode = encrypt("".concat(code, ":").concat(sessionState, ":").concat(decrypt(decodeURIComponent(authState))));
    callAPI(url, "post", { code: authCode }, function (response) {
        if (response.data && response.data.success) {
            cookiesManager.setCookie(cookieKeysEnum.APP_SESSIONID, sessionState);
            cb(true);
        }
        else {
            dispatch(setLoader(false));
            cb(false);
        }
    });
};
export var flagFeature = function (callback, dispatch) {
    var url = getApiUrl("openAPI", "flagFeature", true);
    dispatch(setLoader(true));
    callAPI(url, "get", null, function (response) {
        dispatch(setLoader(false));
        if (response.data && response.data.Status) {
            callback(response);
        }
        else {
            var uniqueRequestId = getApiUniqueRequestId(url);
            dispatch(setMessage(true, response.data.Message || "8166", uniqueRequestId));
        }
    });
};
export var setGlobalSearchText = function (data) { return ({
    type: types.SET_GLOBAL_SEARCH_TEXT,
    data: data,
}); };
export var setBallotAdvanceSearch = function (data) { return ({
    type: types.SET_BALLOT_ADVANCE_SEARCH,
    data: data,
}); };
export var setAdvBallotSearchFormData = function (data) { return ({
    type: types.SET_BALLOT_ADVANCE_FORM_DATA,
    data: data,
}); };
export var setQuickSearchSelectedBallotStatus = function (data) { return ({
    type: types.SET_SELECTED_BALLOT_STATUS,
    data: data,
}); };
export var resetQuickSearchSelectedBallotStatus = function (dispatch) {
    dispatch({
        type: types.SET_SELECTED_BALLOT_STATUS,
        data: "All",
    });
};
export var setQuickSearchSelectedUser = function (data) { return ({
    type: types.SET_QUICK_SEARCH_SELECTED_USER,
    data: data,
}); };
export var setQuickSearchSelectedLevel = function (data) { return ({
    type: types.SET_QUICK_SEARCH_SELECTED_LEVEL,
    data: data,
}); };
export var resetQuickSearchSelectedLevel = function (dispatch) {
    dispatch({
        type: types.SET_QUICK_SEARCH_SELECTED_LEVEL,
        data: [],
    });
};
export var setIsUserOfficer = function (data) { return ({
    type: types.SET_IS_USER_OFFICER,
    data: data,
}); };
export default {
    setLoader: setLoader,
    setminiLoader: setminiLoader,
    setMessage: setMessage,
    showSuccessToastMesage: showSuccessToastMesage,
    StorCurrentLocation: StorCurrentLocation,
    logout: logout,
    getPermissionOnPageAction: getPermissionOnPageAction,
    currentLocationStorage: currentLocationStorage,
    setCurrentLocationStorage: setCurrentLocationStorage,
    initiateAzureRequestAction: initiateAzureRequestAction,
    SetExportDataFlag: SetExportDataFlag,
    setBallotAdvanceSearch: setBallotAdvanceSearch,
    setAdvBallotSearchFormData: setAdvBallotSearchFormData,
    SetBallotDetailsFromData: SetBallotDetailsFromData,
    setQuickSearchSelectedBallotStatus: setQuickSearchSelectedBallotStatus,
    setQuickSearchSelectedUser: setQuickSearchSelectedUser,
    setQuickSearchSelectedLevel: setQuickSearchSelectedLevel,
    setIsUserOfficer: setIsUserOfficer,
    SetWorkItemListData: SetWorkItemListData
};
